import {
    percentOfTxs,
    alertsTriggersList,
    alertDirection
} from "@/pages/statistics/static/filtersLabelsData";

export function createAlertModel(groupBy, logical, groupMatches) {
    return { groupBy, logical, groupMatches }
}

export function createTriggerModel(logical, filter, aggregate = []) {
    return { logical, filter, aggregate }
}

export function createFilterModel(prop, conditions) {
    return { prop,  conditions }
}

export function createConditionModel(value, op) {
    return {value, op}
}

export function selectionDataByTrigger(t, alertData, meta) {
    const amountCurPropIndex = t?.aggregate.findIndex(el => el.prop === 'amountCur')
    const amountPropIndex = t?.aggregate.findIndex(el => el.prop === 'amount')
    const scorePropIndex = t?.filter.findIndex(el => el.prop === 'funds.score')
    const fundsIdPropIndex = t?.filter.findIndex(el => el.prop === 'funds.id')
    const unhostedPropIndex = t?.filter.findIndex(el => el.prop === 'funds.unhosted')
    const ownerPropIndex = t?.filter.findIndex(el => el.prop === 'owner')
    const direction = alertDirection.find(el => el.value === alertData.direction) || alertDirection[0]

    if (unhostedPropIndex !== -1) {
        const { notifyData } = meta
        const formattedAmount = parseFloat(notifyData?.data[0]?.amount) / Math.pow(10, meta?.coinDataAlert?.decimals ?? 1).toFixed(meta?.coinDataAlert?.decimals)
        const from = notifyData?.data[0] && notifyData?.data[0].from
        const to = notifyData?.data[0] && notifyData?.data[0].to
        const tracked = notifyData?.data[0] && notifyData?.data[0].tracked

        return {
            prop: 'funds.unhosted',
            direction,
            from,
            to,
            tracked,
            amount: formattedAmount,
            trigger: t?.aggregate[unhostedPropIndex].prop ? alertsTriggersList.find(el => el.value === 'funds.unhosted') : null,
        }
    } else if (amountCurPropIndex !== -1 && !t?.filter?.length) {
        const formattedAmount = parseFloat(t?.aggregate[amountCurPropIndex]?.conditions[0].value).toFixed(0)
        return {
            prop: 'amountCur',
            direction,
            trigger: t?.aggregate[amountCurPropIndex].prop ? alertsTriggersList.find(el => el.value === 'amountCur') : null,
            amount: formattedAmount,
            percentOfTxs: percentOfTxs.find(q => {
                return q.value === t?.aggregate[amountCurPropIndex]?.conditions[0].op
            })
        }
    } else if (amountPropIndex !== -1 && !t?.filter?.length) {
        const formattedAmount = parseFloat(t?.aggregate[amountPropIndex]?.conditions[0].value) / Math.pow(10, meta?.coinDataAlert?.decimals ?? 1).toFixed(meta?.coinDataAlert?.decimals)
        return {
            prop: 'amount',
            direction,
            trigger: t?.aggregate[amountPropIndex].prop ? alertsTriggersList.find(el => el.value === 'address') : null,
            amount: formattedAmount,
            percentOfTxs: percentOfTxs.find(q => {
                return q.value === t?.aggregate[amountPropIndex]?.conditions[0].op
            })
        }
    } else if (scorePropIndex !== -1) {
        return {
            prop: 'funds.score',
            direction,
            trigger: t?.filter[0].prop ? alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
            scoreFrom: t?.filter.find(el => el.prop === 'funds.score')?.conditions[0].value,
            scoreTo: t?.filter.find(el => el.prop === 'funds.score')?.conditions[1].value,
            share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
            percentOfTxs: percentOfTxs.find(q => {
                return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
            })
        }
    } else if (fundsIdPropIndex !== -1) {
        const conditions = t.filter.find(el => el.prop === 'funds.id').conditions

        if (conditions && conditions.length) {
            const resFundsListIds = conditions[0].value.map(el => { return el })

            return {
                prop: 'funds.id',
                direction,
                trigger: t?.filter[0].prop ? alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
                tags: meta?.tags?.filter(el => resFundsListIds.includes(el._id)) || [],
                types: meta?.types?.filter(el => resFundsListIds.includes(el._id)) || [],
                share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
                percentOfTxs: percentOfTxs.find(q => {
                    return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
                })
            }
        }
    } else if (ownerPropIndex !== -1) {
        const owners = t.filter.find(el => el.prop === 'owner').conditions

        if (owners && owners.length) {
            const resOwnersList = owners[0].value.map(el => { return { owner: el } })

            return {
                prop: 'owner',
                direction,
                trigger: t?.filter[0].prop ? alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
                owner: resOwnersList,
                share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
                percentOfTxs: percentOfTxs.find(q => {
                    return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
                })
            }
        }
    }
}

